import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { AzavistaApiService, IErrorNotification, ITokenPayload, IUser, IEvent, EventType } from '@azavista/servicelib';
import { NotificationMessageType, IAclObject } from '@azavista/components/shared';
import { AzavistaSideNavComponent, IAzavistaSideNavComponentData } from '@azavista/components/side-nav';
import { IMenuItem } from '@azavista/components/side-nav/side-nav.interfaces';
import { AzavistaSharedService, NotificationsService } from '@azavista/components/shared';
import { AzavistaInputFieldService } from '@azavista/components/input-field';
import { SharedService, TranslationKey, AclService, RequiredScopesService, LocalStorageKey, IAppInitializedResult, USER_DEFAULT_USER_IMAGE, IAppThemeChangedData, TRANSLATION_MAP } from './shared';
import { ElevioService } from './elevio.service';
import { BeamerService } from './beamer.service';
import { SubjectsService } from './shared/subjects.service';
import { MixpanelService } from './mixpanel.service';
import { UsageAnalyticsService } from './usage-analytics.service';
import { SideMenuService } from './side-menu.service';
// import { CloneEventService } from './clone-event/clone-event.service';
// import { ICloneEventInputParameters } from './clone-event/interfaces';
import { SmartlookService } from './smartlook.service';
import { AppThemeService } from './app-theme.service';
import { Subject, delay, firstValueFrom, switchMap, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserGuidingService } from './shared/user-guiding.service';

const enum CurrentMenu {
    none = 0,
    main = 1,
    events = 2
}

const cleanExtraSlash = (pathname: string) => pathname.split('/').filter(path => !!path).join('/');
const routerLinkToPathname = (routerLink: string | string[]) => {
    if (typeof routerLink === 'string') {
        return cleanExtraSlash(routerLink);
    }
    if (Array.isArray(routerLink)) {
        return routerLink.join('/');
    }
};

@Component({
    selector: 'azavista3-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

    sideNavData: IAzavistaSideNavComponentData;
    sidenavCollapsed = false;
    isLoggedIn = false;
    isNavbarFocussed = false;
    currentMenu = CurrentMenu.none;
    currentEventId = '';
    currentUser: IUser;
    activeTopMenuPage: 'general-dashboard' | 'events' | 'organization' | 'other' = 'other';
    // TODO: Get from configuration ?
    serverStatusCheckInterval = 10000;
    serverConnectionFailed = false;

    canGoToLegacy = false;
    lastLoadedEvent: IEvent;

    eventsModuleAcl: IAclObject;
    userDefaultImage = USER_DEFAULT_USER_IMAGE;

    private routeNavigationStartUrl = '';
    private readonly destroy$ = new Subject<void>();

    translations = TRANSLATION_MAP;

    @ViewChild(AzavistaSideNavComponent) sideNavEl?: AzavistaSideNavComponent;

    constructor(
        private apiSvc: AzavistaApiService, private router: Router, private translateSvc: TranslateService,
        private sharedSvc: SharedService, private aclSvc: AclService, private cmpSharedSvc: AzavistaSharedService,
        private notificationsSvc: NotificationsService, private rsSvc: RequiredScopesService,
        private elevioSvc: ElevioService, private mixpanelSvc: MixpanelService,
        private beamerService: BeamerService, private subjectsSvc: SubjectsService, private activatedRoute: ActivatedRoute,
        private usageAnalyticsSvc: UsageAnalyticsService,
        private sideMenuSvc: SideMenuService, private smartlookSvc: SmartlookService,
        private dateAdapter: DateAdapter<any>, private cmpInputFieldSvc: AzavistaInputFieldService,
        private appThemeSvc: AppThemeService, private ngZone: NgZone,
        private userGuiding: UserGuidingService,
        private renderer: Renderer2) {
    }

    async ngOnInit(): Promise<void> {
        this.sharedSvc.renderer = this.renderer;
        this.subjectsSvc.getEventLoadedSubject().subscribe({
            next: event => this.eventLoaded(event)
        });
        const defaultLanguage = this.sharedSvc.getDefaultLanguage();
        this.sideNavData = { menuItems: [] };
        this.translateSvc.setDefaultLang(defaultLanguage);
        this.translateSvc.use(defaultLanguage);
        this.dateAdapter.setLocale(defaultLanguage);

        this.subjectsSvc.getAppInitialized().subscribe({
            next: appInitResult => this.appInitialized(appInitResult)
        });

        this.sideMenuSvc.sideNavCollapsedToggleTrigger$.subscribe(async newSideNavCollapsedState => {
            await firstValueFrom(this.ngZone.onStable);
            if (newSideNavCollapsedState == null || this.sideNavEl?.sidebarCollapsed !== newSideNavCollapsedState) {
                this.sideNavEl?.toggleSidebarCollapsedState();
            }
        });
    }

    ngAfterViewInit(): void {
        this.sharedSvc.loadChatBotScript();
    }

    async appInitialized(appInit: IAppInitializedResult): Promise<void> {
        this.subjectsSvc.getThemeChangedObservable().pipe(takeUntil(this.destroy$)).subscribe(
            appThemeChangedData => this.processAppThemeChanged(appThemeChangedData)
        );
        this.subjectsSvc.getAuthenticationNotification().subscribe({
            next: tokenPayload => this.processAuthenticationNotification(tokenPayload)
        });
        this.subjectsSvc.getErrorNotification().subscribe({
            next: errData => this.processApiErrorNotification(errData)
        });
        this.subjectsSvc.getCurrentUser().subscribe({
            next: currentUser => this.processCurrentUser(currentUser)
        });
        const authResponse = appInit.authenticateResponse;
        if (authResponse) {
            this.aclSvc.setTokenPayload(authResponse.token);
            this.setComponentsAclData(authResponse.token);
            this.apiSvc.setCurrentUserFromTokenInfo();
            this.canGoToLegacy = authResponse.token.az2.includes('w');
            this.eventsModuleAcl = this.aclSvc.getAclObjectForCurrentUser(this.rsSvc.getEventsMenuScopes());
            // this.addSideMenu();
        } else {
            this.aclSvc.setTokenPayload(null);
            // this.navigateToLogin();
        }

        this.router.events.subscribe({
            next: event => this.processRouterEvent(event)
        });

        if (environment.production) {
            this.startServerStatusCheck();
        }
        this.beamerService.init();
        this.usageAnalyticsSvc.init();
        this.mixpanelSvc.init();
        this.smartlookSvc.init();

        const url = new URL(window.location.toString());
        const themeId = url.searchParams.get('theme');
        if (themeId) {
            this.subjectsSvc.getThemeChangedSubject().next({ themeCssUrl: environment.resourcesUrlPrefix + `theme/${themeId}.css` });
        } else {
            this.appThemeSvc.addAppThemeStylesheet('assets/themes/default.css');
        }
    }

    async eventLoaded(event: IEvent): Promise<void> {
        // Determine what should be the labels for side menu items
        // We could have null event if we never visited one but the user navigated to builder page
        // like after signing in directly going to email template - view - edit template
        if (event) {
            this.lastLoadedEvent = event;
            this.setEventMenuItemsLabels(event.type);
            await this.adjustEventMenuItemsVisibility(event.type, event.id);
            this.adjustEventMenuQueryParam(event.id);
        }
    }

    adjustEventMenuQueryParam(eventId: string): void {
        // const clonedSideNavData = this.sharedSvc.clone(this.sideNavData);
        const clonedSideNavData = this.sideNavData;
        for (const menuItem of clonedSideNavData.menuItems) {
            if (menuItem && menuItem.queryParams && menuItem.queryParams.eventId) {
                menuItem.queryParams.eventId = eventId;
            }
            if (menuItem.children) {
                for (const child of menuItem.children) {
                    if (child && child.queryParams && child.queryParams.eventId) {
                        child.queryParams.eventId = eventId;
                    }
                }
            }
        }
        this.sideNavData = clonedSideNavData;
    }

    async adjustEventMenuItemsVisibility(eventType: EventType, eventId: string): Promise<void> {
        if (eventType === EventType.workflow) {
            const menuItemsToRemove = [
                'event-engagement-app'
            ];
            this.sideNavData.menuItems = this.sideNavData.menuItems.filter(menuItem =>
                !menuItemsToRemove.includes(menuItem.id)
            );
        }
        if (eventType === EventType.event || eventType === EventType.template) {
            this.sideNavData = await this.createEventSideMenu(eventId);
        }
    }

    setEventMenuItemsLabels(eventType: EventType): void {
        const menuItemsIdsMap: IMenuItemTranslationKeyMap[] = [
            {
                id: 'event-dashboard',
                eventTranslationKey: TranslationKey.eventDashboard,
                workflowTranslationKey: TranslationKey.requestDashboard
            },
            {
                id: 'event-participants-parent',
                eventTranslationKey: TranslationKey.participants,
                workflowTranslationKey: TranslationKey.requesters,
                children: [
                    {
                        id: 'event-participants-list',
                        eventTranslationKey: TranslationKey.participantsList,
                        workflowTranslationKey: TranslationKey.requestersList
                    }
                ]
            },
            {
                id: 'event-workflows',
                eventTranslationKey: TranslationKey.workflows,
                workflowTranslationKey: TranslationKey.workflowSettings,
                children: [
                    {
                        id: 'event-workflow-automations',
                        eventTranslationKey: TranslationKey.workflowAutomations,
                        workflowTranslationKey: TranslationKey.automations
                    }
                ]
            }
        ];
        for (const menuItem of this.sideNavData.menuItems) {
            const mapItem = this.setMenuItemTranslationKey(menuItem, menuItemsIdsMap, eventType);
            if (menuItem.children && mapItem && mapItem.children) {
                for (const childMenuItem of menuItem.children) {
                    this.setMenuItemTranslationKey(childMenuItem, mapItem.children, eventType);
                }
            }
        }
    }

    setMenuItemTranslationKey(menuItem: IMenuItem, map: IMenuItemTranslationKeyMap[], eventType: EventType): IMenuItemTranslationKeyMap {
        const mapItem = map.find(x => x.id === menuItem.id);
        if (mapItem) {
            if (eventType === EventType.event || eventType === EventType.template) {
                menuItem.textTranslationKey = mapItem.eventTranslationKey;
            } else if (eventType === EventType.workflow) {
                menuItem.textTranslationKey = mapItem.workflowTranslationKey;
            }
        }
        return mapItem;
    }

    startServerStatusCheck(): void {
        setTimeout(async () => {
            try {
                await this.apiSvc.getServerStatus();
                this.serverConnectionFailed = false;
            } catch (err) {
                this.serverConnectionFailed = true;
            }
            this.startServerStatusCheck();
        }, this.serverStatusCheckInterval);
    }

    processApiErrorNotification(err: IErrorNotification): void {
        if (this.sharedSvc.isApiAuthenticationError(err)) {
            // Navigate to login and put returnUrl in the URL
            this.navigateToLogin(window.location.href);
            return;
        }
        try {
            const msg = this.sharedSvc.getErrorText(err);
            this.notificationsSvc.showMessage(NotificationMessageType.error, msg);
        } catch (err) {
            this.notificationsSvc.showMessage(NotificationMessageType.error, err.toString());
        }
    }

    addSideMenu(): void {
        this.translateSvc.get('USERS').subscribe(() => {
            this.sideNavData = this.sideMenuSvc.createMainSideMenu();
        });
    }

    async createEventSideMenu(eventId: string): Promise<IAzavistaSideNavComponentData> {
        const currentMenuItems = this.sideNavData?.menuItems;
        const menu: IAzavistaSideNavComponentData = { menuItems: [] } as IAzavistaSideNavComponentData;
        await this.addSideMenuEventDetails(menu, eventId, currentMenuItems);
        return menu;
    }

    async addSideMenuEventDetails(
        menu: IAzavistaSideNavComponentData, eventId: string, currentMenuItems?: IMenuItem[]
    ): Promise<IAzavistaSideNavComponentData> {
        if (!this.lastLoadedEvent || (eventId && this.lastLoadedEvent.id !== eventId)) {
            this.lastLoadedEvent = await this.apiSvc.getEvent(eventId);
        }
        const teamIds = this.lastLoadedEvent.team_ids;
        return this.sideMenuSvc.addSideMenuEventDetails(menu, eventId, teamIds, currentMenuItems);
    }

    switchSideMenuToMain(refresh?: boolean): void {
        if (this.currentMenu === CurrentMenu.main && !refresh) {
            return;
        }
        this.sideNavData = this.sideMenuSvc.createMainSideMenu();
        this.currentMenu = CurrentMenu.main;
    }

    async switchSideMenuToEvent(eventId: string, refresh?: boolean): Promise<void> {
        if (this.currentMenu === CurrentMenu.events && this.currentEventId === eventId && !refresh) {
            return;
        }
        this.sideNavData = await this.createEventSideMenu(eventId);
        this.currentMenu = CurrentMenu.events;
        this.currentEventId = eventId;
    }

    onSidebarCollapsedChanged(collapsed: boolean): void {
        this.sidenavCollapsed = collapsed;
    }

    onMenuClicked(menuItem: IMenuItem): void {
        // Somehow check if this parent has its own route or it just redirects to some other default route
        // This is specified in the routes as "path:'', redirectTo: '...'"
        // For now assume that if the parent has children - navigate to the first children router link
        // If the parent does not have children - does nothing - it should have router link and will navigate to it
        // We should also do nothing if a child of this parent is already selected and shown
        // Otherwise, currently selected child will be replaced with the first one
        if (menuItem.children?.length > 0) {
            // Navigate to the first item
            const firstChildMenuItem = menuItem.children[0];
            const routerLink = Array.isArray(firstChildMenuItem.routerLink)
                ? firstChildMenuItem.routerLink : [firstChildMenuItem.routerLink];
            menuItem.expanded = true;
            this.router.navigate(routerLink, { queryParams: firstChildMenuItem.queryParams });
        }
    }

    processRouterEvent(event: Event): void {
        if (event instanceof NavigationStart) {
            this.routeNavigationStartUrl = event.url;
            if (!event.url.includes('/login') && !this.apiSvc.isAuthenticated()) {
                // this.navigateToLogin();
            } else {
                if (!this.apiSvc.isAuthenticated()) {
                    return;
                }
                this.translateSvc.get('USERS').subscribe(async () => {
                    if (this.isUrlInEventContext(event.url)) {
                        const eventId = this.getEventIdQueryParam();
                        await this.switchSideMenuToEvent(eventId);
                        this.expandCorrespondingMenuItem(event.url);
                        await this.eventLoaded(this.lastLoadedEvent);
                    } else {
                        // Switch side menu to global and expand corresponding menu item
                        this.switchSideMenuToMain();
                        this.expandCorrespondingMenuItem(event.url);
                    }
                });
            }
            this.setActiveTopMenuPage(event.url);
        } else if (event instanceof NavigationEnd) {
            this.expandCorrespondingMenuItem(event.url);
        }
    }

    getEventIdQueryParam(): string {
        const currentNavigation = this.router.getCurrentNavigation();
        if (!currentNavigation) {
            return '';
        }
        if (currentNavigation.extras
            && currentNavigation.extras.queryParams
            && currentNavigation.extras.queryParams.eventId) {
            return currentNavigation.extras.queryParams.eventId;
        } else if (currentNavigation.extractedUrl
            && currentNavigation.extractedUrl.queryParams
            && currentNavigation.extractedUrl.queryParams.eventId) {
            return currentNavigation.extractedUrl.queryParams.eventId;
        } else {
            return '';
        }
    }

    isUrlInEventContext(url: string): boolean {
        const { pathname, searchParams } = new URL(url, window.location.origin);
        return pathname.indexOf('/event/') === 0
            || pathname.indexOf('/event?') === 0
            || searchParams.has('eventId');
    }

    expandCorrespondingMenuItem(url: string): void {
        const unpackUrlRegex = /^\/([^\/]+)\/?([^\/]+)?\/?/;
        const urlMatchResult = url.match(unpackUrlRegex);
        if (!urlMatchResult) {
            return;
        }
        const windowUrl = new URL(window.location.origin + url);
        const urlWithoutQuery = cleanExtraSlash(windowUrl.pathname);
        const urlThreeLevelPathWithoutQuery = urlWithoutQuery.split('/').slice(0, 3).join('/');
        const urlTwoLevelPathWithoutQuery = urlWithoutQuery.split('/').slice(0, 2).join('/');
        // const [_, globalModuleUrl, eventModuleUrl] = urlMatchResult;
        const globalModuleUrl = urlMatchResult[1];
        if (globalModuleUrl === 'event') {
            // const routerUrl = new URL(window.location.origin + urlWithoutQuery);
            // if (pathName.startsWith('/')) {
            //     pathName = pathName.substr(1);
            // }
            // if (pathName.endsWith('/')) {
            //     pathName = pathName.substr(0, pathName.length - 1);
            // }
            // this.sideNavData.menuItems.forEach(menuItem =>
            // // menuItem.expanded = menuItem.routerLink === `event/${eventModuleUrl}`
            // {
            //     menuItem.expanded = menuItem.routerLink === pathName;
            // });
            const menuFromUrl = this.findMenuByUrl(urlTwoLevelPathWithoutQuery);
            const findMenuResult = menuFromUrl?.parentMenu ? menuFromUrl : this.findMenuByUrl(urlThreeLevelPathWithoutQuery);
            if (findMenuResult.parentMenu) {
                for (const parentMenuItem of this.sideNavData.menuItems) {
                    parentMenuItem.expanded = false;
                }
                findMenuResult.parentMenu.expanded = true;
            }
        } else {
            // Find the parent of the menu with specified url
            const menuFromUrl = this.findMenuByUrl(urlTwoLevelPathWithoutQuery);
            const findMenuResult = menuFromUrl?.parentMenu ? menuFromUrl : this.findMenuByUrl(urlThreeLevelPathWithoutQuery);
            if (findMenuResult.parentMenu) {
                for (const parentMenuItem of this.sideNavData.menuItems) {
                    parentMenuItem.expanded = false;
                }
                findMenuResult.parentMenu.expanded = true;
            }
        }
    }



    findMenuByUrl(url: string): IFindMenuByUrlResult {
        const result: IFindMenuByUrlResult = { parentMenu: null, childMenu: null };
        const noSlashesUrl = cleanExtraSlash(url);
        for (const menuItem of this.sideNavData.menuItems) {
            menuItem.expanded = false;

            if (routerLinkToPathname(menuItem.routerLink) === noSlashesUrl) {
                result.parentMenu = menuItem;
                return result;
            }
        }
        // Parent was not found - try to find child
        for (const parentMenuItem of this.sideNavData.menuItems) {
            if (parentMenuItem?.children?.length > 0) {
                for (const childMenuItem of parentMenuItem.children) {
                    if (routerLinkToPathname(childMenuItem.routerLink) === noSlashesUrl) {
                        result.parentMenu = parentMenuItem;
                        result.childMenu = childMenuItem;
                        return result;
                    }
                }
            }
        }
        return result;
    }

    processAuthenticationNotification(tokenPayload: ITokenPayload): void {
        this.aclSvc.setTokenPayload(tokenPayload);
        if (!tokenPayload) {
            this.isLoggedIn = false;
        } else {
            this.setComponentsAclData(tokenPayload);
            this.isLoggedIn = true;
            this.canGoToLegacy = tokenPayload.az2.includes('w');
            this.eventsModuleAcl = this.aclSvc.getAclObjectForCurrentUser(this.rsSvc.getEventsMenuScopes());
            this.elevioSvc.startElevio();
        }
        this.sharedSvc.loadChatBotScript();
    }

    async processCurrentUser(user: IUser) {
        this.currentUser = user;
        if (!user) {
            return;
        }
        this.setDefaultDisplayFormats(user.date_format, user.datetime_format, user.time_format, user.language, user.timezone);
        await this.refreshSideNav();
        await this.setLanguage(user);
    }

    async refreshSideNav(): Promise<void> {
        const url = this.routeNavigationStartUrl; // this.router.url;
        if (this.isUrlInEventContext(url)) {
            const eventId = this.getEventIdQueryParam();
            await this.switchSideMenuToEvent(eventId, true);
            this.expandCorrespondingMenuItem(url);
            await this.eventLoaded(this.lastLoadedEvent);
        } else {
            this.switchSideMenuToMain(true);
            this.expandCorrespondingMenuItem(url);
        }
    }

    async setLanguage(user: IUser): Promise<void> {
        const localStorageLanguage = window.localStorage.getItem(LocalStorageKey.userLanguage);
        if (localStorageLanguage && (localStorageLanguage !== user.language)) {
            const req = { id: user.id, language: localStorageLanguage } as IUser;
            const updatedUser = await this.apiSvc.updateUser(req);
            this.apiSvc.setCurrentUser(updatedUser);
            return;
        }
        if (user.language) {
            this.translateSvc.use(user.language);
        }
    }

    setDefaultDisplayFormats(dateFormat: string, dateTimeFormat: string, timeFormat: string, locale: string, timezone: string): void {
        this.cmpSharedSvc.setDateDisplayFormat(dateFormat);
        this.cmpSharedSvc.setDateTimeDisplayFormat(dateTimeFormat);
        this.cmpSharedSvc.setTimeDisplayFormat(timeFormat);
        this.cmpSharedSvc.setLocale(locale);
        this.cmpInputFieldSvc.setDefaultTimeZone(timezone);
        this.dateAdapter.setLocale(locale);
    }

    navigateToLogin(returnUrl?: string): void {
        if (!returnUrl) {
            this.router.navigate(['/login']);
        } else {
            const queryParams = { returnUrl: returnUrl };
            this.router.navigate(['/login'], { queryParams: queryParams });
        }
    }

    setComponentsAclData(tokenPayload: ITokenPayload): void {
        this.cmpSharedSvc.setAclData({
            isAdmin: tokenPayload.g,
            teamsWithScopes: tokenPayload.s
        });
    }

    hasEventsAccess(): boolean {
        return this.aclSvc.hasAnyAccessToEventsModule();
    }

    navigateToAzavistaV2(): void {
        const action = this.sharedSvc.getWebsiteUrl('/users/session');
        const jwt = JSON.parse(localStorage.getItem('azavista.apiService.authResponse')).access_token;
        const f = document.getElementById('user-session');
        if (f) {
            f.remove();
        }

        const form = document.createElement('form');
        form.setAttribute('id', 'user-session');
        form.action = action;
        form.method = 'POST';

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'jwt';
        input.value = jwt;

        const button = document.createElement('button');
        button.type = 'submit';
        form.appendChild(input);
        form.appendChild(button);
        form.target = '_blank';

        document.body.appendChild(form);
        button.click();
    }

    navigateToMyAccount(): void {
        this.router.navigate(['/my-account']);
    }

    navigateToMyJobs(): void {
        this.router.navigate(['/my-jobs']);
    }

    navigateToEngagement(): void {
        const origin = window.origin;
        if (
            origin.includes('localhost')
            || origin.includes('3.0.testing')
            || origin.includes('3.0.devk8s')
        ) {
            this.router.navigate(['/gamification']);
        }
    }

    launchUserGuidingChecklist(checklistId: number) {
        this.userGuiding.launchChecklist(checklistId);
    }

    onQuickAccessItemSelected(event: IEvent): void {
        if (!event?.id) {
            return;
        }
        // The detour below ('/') is necessary to account for cases in which the quick access is invoked while on
        // the dashboard route of some event (otherwise Angular won't reload the dashboard routed component)
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/', 'event', 'dashboard'], { queryParams: { eventId: event.id, type: 'event' } });
        });
    }

    setActiveTopMenuPage(url: string): void {
        if (/^\/dashboard$/.test(url)) {
            this.activeTopMenuPage = 'general-dashboard';
        } else if (this.currentMenu === CurrentMenu.events || /^\/events/.test(url)) {
            this.activeTopMenuPage = 'events';
        } else if (this.currentMenu === CurrentMenu.main) {
            this.activeTopMenuPage = 'organization';
        } else {
            this.activeTopMenuPage = 'other';
        }
    }

    async logout(): Promise<void> {
        this.apiSvc.logout();
        this.userGuiding.hideChecklist();
        this.isLoggedIn = false;
        this.sharedSvc.loadChatBotScript();
        this.router.navigate(['/login']);
        this.elevioSvc.stopElevio();
    }

    async refreshToken(): Promise<void> {
        try {
            // temporary "solution" for testing purposes - Should be deleted at some point
            await this.apiSvc.refreshToken();
            this.notificationsSvc.showMessage(NotificationMessageType.info, 'Token successfully refreshed');
        } catch (e) {

        }
    }

    private async processAppThemeChanged(appThemeChangedData: IAppThemeChangedData): Promise<void> {
        try {
            await this.appThemeSvc.addAppThemeStylesheet(appThemeChangedData.themeCssUrl);
        } catch { }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getUserProfileImageUrl = async (user: Pick<IUser, 'profile_image_id'>) => {
        return user?.profile_image_id ? this.sharedSvc.getDocumentUrl(user.profile_image_id) : null;
    };

    async onQuickAccessSearchClick() {
        this.toggleNavbarFocused(true);
        await firstValueFrom(this.ngZone.onStable);
        await delay(300);
        const quickAccessSearchAutocompletePanel = document.querySelector<HTMLDivElement>('.quick-access-search__autocomplete-panel.mat-mdc-autocomplete-panel');
        if (quickAccessSearchAutocompletePanel) {
            quickAccessSearchAutocompletePanel.style.minWidth = `${document.querySelector<HTMLDivElement>('.quick-access-search-widget').clientWidth}px` ;
        }
    }

    toggleNavbarFocused(isFocussed?: boolean) {
        this.isNavbarFocussed = isFocussed ?? !this.isNavbarFocussed;
    }
}

interface IMenuItemTranslationKeyMap {
    id: string;
    eventTranslationKey: TranslationKey;
    workflowTranslationKey: TranslationKey;
    children?: IMenuItemTranslationKeyMap[];
}

interface IFindMenuByUrlResult {
    parentMenu: IMenuItem;
    childMenu: IMenuItem;
}
